@import url("https://fonts.googleapis.com/css2?family=Libre+Franklin:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
body {
  font-family: "Libre Franklin", sans-serif !important;
  width: 100vw;
  overflow-x: hidden;
}

#contentBG {
  background: var(--background-gray-00, #f8f8f8);
  min-height: calc(100vh - 200px);
}

button.greyFull {
  border-radius: 100px;
  border: 1px solid var(--tekst-display-gray-01, #2f2a2a);
  background: var(--background-green-01, #1e3c3c);
  color: var(--tekst-body-gray-03, #f7f7f7);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  letter-spacing: -0.32px;
  padding: 10px 24px;
  display: flex;
  align-items: center;
  gap: 10px;
}
.black {
  color: black;
}

.lineSmooth {
  width: 100%;
  height: 10px;
  min-width: 300px;
  text-align: center;
  position: relative;
}
.timeLineObject .image {
  height: 15px;
}

.lineSmooth .top {
  position: absolute;
  left: 47%;
  top: -7.5px;
  width: 15px;
  height: 15px;
  transform: rotate(45deg);
}

.lineSmooth .bottom {
  position: absolute;
  left: 47%;
  bottom: -7.5px;
  width: 15px;
  height: 15px;
  transform: rotate(45deg);
}
.lineSmooth.orange div {
  background: #ff9f00;
}
.lineSmooth.orange {
  background: #ff9f00;
}
.lineSmooth.grey div {
  background: #d1d1d1;
}
.lineSmooth.grey {
  background: #d1d1d1;
}
.lineSmooth.greyThick div {
  background: #777777;
}
.lineSmooth.greyThick {
  background: #777777;
}

.poprawkaSingleMobile {
  padding: 10px;
  word-break: normal;
}

.poprawkaSingleMobile.AddPlik p {
  word-break: normal;
}
button.whiteFull {
  border-radius: 100px;
  border: 1px solid var(--tekst-display-gray-01, #fff);
  background: var(--background-green-01, #fff);
  color: var(--tekst-body-gray-03, #000);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  letter-spacing: -0.32px;
  padding: 10px 24px;
}

#notSeen {
  display: none;
  transition: all 0.5s ease-in-out;
}

#canBeSeen {
  display: table-row;
  transition: all 0.5s ease-in-out;
}
#rotated {
  transform: rotate(180deg);
  transition: all 0.5s ease-in-out;
}
#notRotated {
  transform: rotate(0deg);
  transition: all 0.5s ease-in-out;
}

button.remove {
  padding: 6px 12px;
  border-radius: 100px;
  border: 1px solid var(--tekst-display-gray-01, #f03939);
  color: var(--tekst-display-gray-01, #f03939);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 19.2px */
  letter-spacing: -0.32px;
  background: white;
}

#login .dropdown a {
  margin-top: 0;
  color: var(--tekst-display-gray-01, #2f2a2a);
  font-family: "Libre Franklin";
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 16.8px */
  letter-spacing: -0.28px;
  padding: 10px;
}

#login .dropdown li {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
}

button.outlineGrey {
  padding: 12px 24px 12px 24px;
  border-radius: 100px;
  border: 1px solid var(--tekst-display-gray-01, #2f2a2a);
  color: var(--tekst-display-gray-01, #2f2a2a);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 19.2px */
  letter-spacing: -0.32px;
  background: white;
}
.rstdi .delete {
  justify-content: flex-end;
  padding-right: 10px;
}

.topBar {
  background: var(--background-orange-02, #f8e3cc);
  color: var(--tekst-display-gray-01, #2f2a2a);
  text-align: start;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  padding: 10px;
  line-height: 154%; /* 33.88px */
  letter-spacing: -0.44px;
}

.single-chart {
  width: 100%;
}

.circular-chart {
  display: block;
  margin: 10px auto;
  max-width: 80%;
  max-height: 250px;
}

.circle-bg {
  fill: none;
  stroke: #f6a54740;
  stroke-width: 3.4px;
}

.circular-chart .circle {
  fill: none;
  stroke-width: 3.4px;
  animation: progress 1s ease-out forwards;
}

@keyframes progress {
  0% {
    stroke-dasharray: 0 100;
  }
}

.circular-chart.orange .circle {
  stroke: #ff9f00;
}

.percentage {
  font-size: 19px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  fill: #2f2a2a;
  font-family: sans-serif;
  font-size: 0.4em;
  text-anchor: middle;
}

.inner {
  font-size: 0.2em;
}

#homeUser #circleProc {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  padding: 20px 40px;
}
.notification-success {
  background-color: #1e3c3c !important;
}

#homeUser {
  max-height: calc(100vh - 140px);
  overflow: auto;
  padding-bottom: 20px;
}

.rowFullWidth.row {
  height: 100%;
  padding-right: 1.5rem;
}

.rowFullWidth.row > .col-sm-12 {
  min-height: calc(100vh - 250px);
}
#homeUser #circleProc .topText {
  color: var(--tekst-display-gray-01, #2f2a2a);
  text-align: center;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.38px;
}

#homeUser #circleProc .bottomText {
  color: var(--tekst-display-gray-01, #2f2a2a);
  text-align: center;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.38px;
}

#rightSideCircleTop {
  border-left: 1px dashed #777;
}

.timeLineInner {
  overflow-x: scroll;
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  flex-wrap: nowrap;
  flex-grow: 0;
  height: 261px;
  gap: -5px;
}

.timeLineObject {
  text-align: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 300px;
  flex-shrink: 0;
  margin-right: -5px;
}

.timeLineObject#reversed {
  flex-direction: column-reverse;
}

.timeLineObject svg {
  width: 100%;
}

.timeLineObject#reversed .circle {
  margin-bottom: 16px;
}

.timeLineObject .content {
  max-width: 200px;
  max-height: 100px;
  margin-top: 10px;
}
.timeLineObject .image {
  margin-left: -5px;
  /* margin-top: -11px; */
}
.timeLineObject .circle {
  height: 60px;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #777;
  border-radius: 50%;
  color: var(--tekst-body-gray-01, #777);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.timeLineInner .line {
  width: 1px;
  height: 50px;
  background-color: #777;
  margin-left: -10px;
}

.timeLineObject .circle {
  margin-left: -10px;
}

.bottomButtonsLista {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 1.5rem;
  border-bottom: 1px solid #2f2a2a;
}

.deliveryBudzetTruck {
  display: flex;
  align-items: center;
  gap: 1rem;
  flex-direction: row;
  margin-bottom: 0 !important;
}

.timeLineObject .content p {
  color: #888;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  max-height: 56px;
  overflow: hidden;
}
.timeLineObject .content p strong {
  color: #888;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.timeline ::-webkit-scrollbar {
  width: 2px;
  height: 10px;
}

/* Track */
.timeline ::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.timeline ::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.timeline ::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.rightSide.calendar {
  border-radius: 4px;
  background: var(--background-orange-01, #f9f1e8);
  padding: 10px;
  width: 340px;
  height: 350px;
}

.rowCalendar {
  display: flex;
}

.rowCalendar .leftSide {
  width: calc(100% - 340px);
}

.rightSide.calendar .react-calendar__month-view__days__day {
  color: var(--tekst-body-gray-01, #777);
  text-align: center;
  font-variant-numeric: lining-nums tabular-nums;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  height: 30px;
  width: 30px;
  text-align: center;
  flex: unset !important;
  aspect-ratio: 1/1;
  border: 1px solid black;
  background: transparent;
  border-radius: 50%;
}

.rightSide.calendar .react-calendar__month-view__days {
  gap: 15px;
  align-items: center;
  justify-content: center;
}

.rightSide.calendar .react-calendar__month-view__weekdays__weekday abbr[title] {
  text-align: center;
  text-decoration: none !important;
  color: var(--tekst-body-gray-01, #777);
  text-align: center;
  font-variant-numeric: lining-nums tabular-nums;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.rightSide.calendar .react-calendar__tile--active {
  background: #ff9f00;
  color: white;
  border-color: #ff9f00;
}
.react-calendar__month-view__weekdays__weekday {
  text-align: center;
  margin: 10px 0;
}
.react-calendar__navigation__label {
  order: -1;
  border: none;
  text-align: start;
  opacity: 1;
  color: var(--tekst-body-gray-01, #777);
  font-variant-numeric: lining-nums tabular-nums;
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}
.react-calendar__navigation__arrow {
  color: #777;
  background: transparent;
}
.react-calendar__navigation__arrow {
  border: none;
  font-size: 30px;
}

.react-calendar__navigation {
  display: flex;
  justify-content: space-between;
}
.react-calendar__navigation
  .rightSide.calendar
  .react-calendar__month-view__weekdays__weekday {
  text-align: center;
  margin-top: 10px;

  margin-bottom: 10px;
}

.react-calendar__navigation__prev2-button,
.react-calendar__navigation__next2-button {
  display: none !important;
}

.react-calendar__month-view__days__day--neighboringMonth {
  opacity: 0.5;
}

.calendarHome .leftSide .title {
  color: var(--tekst-body-gray-01, #777);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
}

.calendarHome .leftSide .content {
  background: white;
  padding: 10px;
}
.calendarHome .leftSide {
  padding: 0 10px;
}
.calendarHome .leftSide .content textarea {
  border: none;
  width: 100%;
  height: 100%;
  min-height: 240px;
}
.calendarHome .leftSide .content textarea:focus {
  outline: none;
}

.calendarHome .leftSide .content p.date {
  border-bottom: 1px solid var(--tekst-body-gray-02, #d1d1d1);
}

.buttonBottomAdd {
  margin-top: 20px;
  text-align: end;
  text-align: -webkit-right;
}
.c-pointer {
  cursor: pointer;
}
.zakresPracMain {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 20px;
  justify-content: center;
}
.buttonBottomAdd button,
button.accept {
  display: flex;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 100px;
  border: 1px solid var(--CTA-orange, #f0a139);
  color: var(--CTA-orange, #f0a139);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  background: transparent;
  line-height: 120%; /* 16.8px */
  letter-spacing: -0.28px;
}
button.accept svg {
  font-size: 28px;
}
.buttonBottomAdd button:hover,
button.accept:hover {
  background: #f0a139;
  color: white;
}
.bottomButtonsLista .remove {
  padding: 12px 24px;
}
.remove:hover {
  background: #f03939;
  color: white;
}
.buttonBottomAdd button svg {
  font-size: 27px;
}

.buttonBottomAdd button:hover svg {
  color: white;
}
.inspiracje .section {
  background: white;
  margin: 15px 0;
  padding: 10px;
}
.section.myStyle {
  display: flex;
  flex-direction: row;
  gap: 20px;
}
.choseStyle {
  width: calc(100% - 100px);
}
.choseStyle p {
  color: #000;
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 22.8px */
  letter-spacing: -0.76px;
  margin-bottom: 10px;
}
.choseStyle .dropdown-toggle::after {
  display: none;
}

.choseStyle .dropdown-toggle {
  width: 100%;
  display: flex;
  height: 40px;
  padding: 10px;
  justify-content: space-between;
  align-items: center;
  border: 1px solid var(--tekst-body-gray-02, #d1d1d1);
  background: #fff;
}

.choseStyle #dropdownBottom {
  width: 100%;
  border-radius: 0;
  max-height: 60vh;
  overflow: auto;
}

.choseStyle #dropdownBottom .dropdown-item {
  width: 100%;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  padding: 10px;
  line-height: 120%; /* 16.8px */
  letter-spacing: -0.28px;
  border-bottom: 1px solid var(--tekst-body-gray-02, #d1d1d1);
}

.choseStyle #dropdownBottom .dropdown-item:hover,
.choseStyle #dropdownBottom .dropdown-item#active {
  background: var(--CTA-orange, #f0a139);
  color: white;
}

.choseStyle .dropdown-menu.show {
  padding: 0;
}

.choseStyle select option {
  padding: 10px;
  border-bottom: 1px solid var(--tekst-body-gray-02, #d1d1d1);
}
.choseStyle select option:hover {
  border-bottom: 1px solid var(--tekst-body-gray-02, #d1d1d1);
  background: var(--CTA-orange, #f0a139);
}
.choseStyle select:focus {
  outline: none;
}

.styleDesc textarea {
  width: 100%;
  font-size: 18px;
  border: none;
  height: 150px;
}

.styleDesc textarea:focus {
  outline: none;
}
.imagesPodglad p.addPhoto {
  color: var(--tekst-body-gray-02, #d1d1d1);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 14.4px */
  letter-spacing: -0.48px;
}
.imagesPodglad .mainImage {
  height: 40vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--background-gray-01, #ececec);
  margin-bottom: 20px;
  flex-direction: column;
}

.imagesPodglad .bottomImages {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: start;
}
.imagesPodglad {
  max-height: 75vh;
  overflow-y: auto;
}
.imagesPodglad .imageSmall {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25vh;
  background: var(--background-gray-01, #ececec);
  width: calc(33% - 10px);
  flex-direction: column;
}
.myStyle .image img {
  width: 100px;
  border-radius: 50%;
  aspect-ratio: 1/1;
}

.imagesPodglad p {
  color: var(--tekst-display-gray-01, #2f2a2a);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 14.4px */
  letter-spacing: -0.48px;
}

.imagesPodglad .bottomImages p {
  margin-top: 10px;
}

.customize h5 {
  color: #000;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 26.4px */
  letter-spacing: -0.88px;
}
.customize .gridStyles {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
}
.cPointer {
  cursor: pointer;
}
.customize .gridStyles .sec {
  cursor: pointer;
  background-color: white;
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 10px;
  color: var(--tekst-display-gray-01, #2f2a2a);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 16.8px */
  letter-spacing: -0.56px;
}

.img-podglad {
  height: auto;
  max-height: 100%;
  max-width: 100%;
  width: 100%;
  object-fit: cover;
}
.imagesPodglad .iconsPodglad {
  width: calc(100% - 10px);
  position: absolute;
  top: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  left: 5px;
}
.imagesPodglad .iconsPodglad div {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}
.imagesPodglad .iconsPodglad div.icon {
  width: 30px;
  height: 30px;
  font-size: 20px;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
}
.imagesPodglad .image {
  position: relative;
  overflow: hidden;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}

.customize .gridStyles .sec#active {
  outline: 1px solid #f0a139;
}

.choseColor {
  margin-top: 20px;
}

.choseColor h4 {
  color: #000;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 26.4px */
  letter-spacing: -0.88px;
}

.choseMyColors {
  display: flex;
  gap: 30px;
  flex-direction: row;
  margin-top: 20px;
}

.choseMyColors .clickable {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.pallette img {
  border-radius: 50%;
}
.pallette {
  border-right: 2px solid var(--tekst-body-gray-02, #d1d1d1);
  padding-right: 20px;
}

.choseMyColors .clickColor {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
  border: 2px solid #d1d1d1;
}

.choseMyColors .dropdown-menu.show {
  display: flex !important;
  flex-direction: row;
  flex-wrap: wrap;
  width: 260px;
  position: relative;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.1),
    0px 3px 7px 0px rgba(0, 0, 0, 0.1), 0px 14px 14px 0px rgba(0, 0, 0, 0.09),
    0px 31px 18px 0px rgba(0, 0, 0, 0.05), 0px 54px 22px 0px rgba(0, 0, 0, 0.01),
    0px 85px 24px 0px rgba(0, 0, 0, 0);
}

.choseMyColors .dropdown-menu.show svg {
  position: absolute;
  left: 2%;
  width: 10%;
  top: -15px;
}

.choseMyColors .dropdown-menu .dropdown-item {
  width: 60px;
}
.choseMyColors .dropdown-menu .dropdown-item:active {
  background-color: transparent !important;
}
.choseMyColors .dropdown-menu .colorToChose {
  width: 40px;
  height: 40px;
  border: 3px solid #d1d1d1;
  cursor: pointer;
  border-radius: 50%;
}

.clickColor.dropdown-toggle::after {
  display: none;
}

.projectItems {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.projectItem p {
  color: var(--tekst-display-gray-01, #2f2a2a);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 154%; /* 21.56px */
  letter-spacing: -0.56px;
}
.projectItem {
  border-bottom: 1px solid var(--tekst-body-gray-02, #d1d1d1);
  display: flex;
  padding: 10px 20px;
  align-items: center;
  gap: 40px;
  flex-direction: row;
  justify-content: space-between;
}

.projectItem .totheLeft {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 40px;
}

.projectItem .totheLeft p {
  margin-bottom: 0;
  color: var(--tekst-display-gray-01, #2f2a2a);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 154%; /* 21.56px */
  letter-spacing: -0.56px;
}

.projectItem img {
  max-height: 80px;
}

.progressBar {
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-width: 300px;
  max-width: 90vw;
  padding-bottom: 10px;
  position: relative;
}

.progressBar .progressTOP {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  font-size: 14px;
}
.progressBar .progressTOP p {
  color: #000;
  font-style: normal;
  font-weight: 400;
  line-height: 154%; /* 18.48px */
  letter-spacing: -0.48px;
}
.progressBar .progressTOP p.zero {
  margin-left: -10px;
}

.progressBar .progressTOP p.sto {
  margin-right: -25px;
}

.progressBar .bottomPasek {
  height: 5px;
  width: 100%;
  background: #d1d1d1;
  position: absolute;
  bottom: 0;
}
.progressBar .coloredPasek {
  height: 5px;
  background: #f0a139;
  position: absolute;
  bottom: 0;
  z-index: 9;
}

.actualProcentage {
  position: absolute;
  border-radius: 50%;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  bottom: 10px;
  width: 45px;
  background: var(--CTA-orange, #f0a139);
}

.askGPT {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  border: 1px solid var(--tekst-body-gray-02, #d1d1d1);
  padding: 10px;
}
.askGPT .notesLeft {
  width: 60%;
}

.askGPT .slownik {
  width: 40%;
}

.askGPT .notesLeft p {
  color: var(--tekst-display-gray-01, #2f2a2a);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.askGPT textarea {
  border: none;
  width: 100%;
  height: 200px;
  width: 90%;
  background: transparent;
}
.askGPT textarea:focus {
  outline: none;
}

.askGPT .innerFlex {
  display: flex;
  flex-direction: row;
  gap: 6px;
  align-items: center;
}

.askGPT img {
  height: 30px;
  width: 30px;
}

.askGPT .innerFlex p {
  margin-bottom: 0;
  color: var(--tekst-display-gray-01, #2f2a2a);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 14.4px */
  letter-spacing: -0.24px;
}

.askGPT .topSlownik {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
}

.askGPT .slownik {
  display: flex;
  flex-direction: column;
  gap: 10px;
  background: white;
  padding: 10px;
}
.askGPT .slownik textarea {
  padding: 10px;
  width: 100%;
}

#contentSidebar .subMenu {
  display: flex;
  flex-direction: column;
  padding-left: 50px;
  gap: 12px;
}
#contentSidebar .subMenu a {
  text-decoration: none;
  color: var(--background-gray-00, #f8f8f8);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 19.2px */
  letter-spacing: -0.32px;
}

#contentSidebar .subMenu a#active {
  color: #f0a139;
}

.classicTable thead th {
  border-bottom: 1px solid var(--tekst-body-gray-02, #d1d1d1);
  background: var(--background-orange-01, #f9f1e8);
  padding: 10px;
}
.classicTable thead th:first-child {
  padding-left: 20px;
}
.classicTable tbody tr td:first-child {
  padding-left: 20px;
}
.classicTable thead {
  color: var(--tekst-display-gray-01, #2f2a2a);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 154%; /* 21.56px */
  letter-spacing: -0.56px;
  text-transform: uppercase;
}
.classicTable thead th,
.classicTable thead p {
  font-weight: 400;
}
.classicTable tr,
.classicTable td,
.classicTable th {
  text-align: center;
}
.classicTable th .d-flex {
  justify-content: center;
}
.classicTable td .flex-column {
  justify-content: start;
  text-align: start;
}
.classicTable td a {
  color: black;
}
.wholeScreenWhite {
  position: fixed;
  left: 0;
  top: 0;
  background: var(--background-gray-00, #f8f8f8);
  z-index: 99;
  width: 100vw;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.innerForm {
  position: relative;
  border-radius: 6px;
  padding: 20px !important;
  border: 1px solid var(--background-gray-01, #ececec);
  background: #fff;
  max-width: 90vw;
  width: 460px;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.1),
    0px 1px 3px 0px rgba(0, 0, 0, 0.1), 0px 5px 5px 0px rgba(0, 0, 0, 0.09),
    0px 12px 7px 0px rgba(0, 0, 0, 0.05), 0px 21px 8px 0px rgba(0, 0, 0, 0.01),
    0px 33px 9px 0px rgba(0, 0, 0, 0);
}

.innerForm.poprawkiForm {
  width: fit-content;
}
.innerForm.poprawkiForm .innerFlexFunkc {
  gap: 20px;
}
.innerForm.poprawkiForm .AddPlik p {
  color: #000;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 19.2px */
  letter-spacing: -0.64px;
  padding: 0 10px;
}

.innerForm.poprawkiForm .buttomForm p {
  width: 20%;
  text-align: center;
}
.formAGD .AddPlik,
.formAGD .removePlik {
  width: 48%;
  height: 150px;
}
.removePlik p {
  margin-top: 10px;
  margin-bottom: 0;
}
.formAGD .AddPlik p,
.formAGD .removePlik p {
  width: 100%;
  text-align: center;
  margin-top: 0px;
  word-break: break-all;
  margin-bottom: 0;
}
.formAGD .innerFlexFunkc {
  width: 70%;
}

.formAGD .inputTypical {
  width: 70%;
}
.inputItem {
  display: flex;
  margin: 15px 0;
  flex-direction: row;
  align-items: center;
}

.inputItem p {
  margin-bottom: 0;
  width: 30%;
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 154%; /* 24.64px */
  letter-spacing: -0.64px;
}
.dropdown-menu.show {
  width: 100%;
  padding: 0;
}
.inputItem p.dropdown-item:hover,
.inputItem p.dropdown-item#active {
  background: var(--CTA-orange, #f0a139);
  color: white;
}
#imageZoom {
  width: 100%;
  height: auto;
}
.inspiracje #imageZoom {
  width: auto;
  height: 100%;
}
.no-print {
  display: none;
}
.inspiracje .imageMagnifierWrapper {
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
.inspiracje .imageMagnifierWrapper img {
  height: 80vh !important;
}
.inspiracje .imageMagnifierWrapper svg {
  font-size: 40px;
  color: white;
}
.inspiracje .imageMagnifierWrapper figure {
  width: fit-content;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.red {
  color: red;
}
.inputItem p.dropdown-item {
  width: 100%;
  margin-bottom: 0;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 15px;
  line-height: 120%;
  letter-spacing: -0.28px;
  border-bottom: 1px solid var(--tekst-body-gray-02, #d1d1d1);
}
.inputItem .dropdown-toggle.show {
  outline: 2px solid #f0a139;
  border-radius: 6px;
  border: 1px solid var(--CTA-orange, #f0a139);
  background: var(--tekst-body-gray-03, #f7f7f7);
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.1),
    0px 11px 11px 0px rgba(0, 0, 0, 0.09), 0px 24px 14px 0px rgba(0, 0, 0, 0.05),
    0px 42px 17px 0px rgba(0, 0, 0, 0.01), 0px 66px 19px 0px rgba(0, 0, 0, 0);
}
.innerForm .close {
  position: absolute;
  cursor: pointer;
  right: 0;
  top: -50px;
}
.innerForm .dropdown {
  width: 70%;
}
.dropdown-toggle::after {
  display: none !important;
}
.inputItem .dropdown-toggle {
  border-radius: 6px;
  width: 100%;
  display: flex;
  height: 40px;
  padding: 10px;
  justify-content: space-between;
  align-items: center;
  border: 1px solid var(--CTA-orange, #f0a139);

  background: #fff;
}

.innerForm .innerFlexFunkc {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.AddPlik {
  width: 200px;
  cursor: pointer;
  overflow: hidden;
  height: 200px;
  border-radius: 6px;
  border: 1px solid var(--CTA-orange, #f0a139);
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 18px;
}

.AddPlik p {
  margin-bottom: 0;
  margin-top: 10px;
  word-break: break-all;
  text-align: center;
}

.removePlik {
  cursor: pointer;
  width: 200px;
  height: 200px;
  border-radius: 6px;
  border: 1px solid var(--tekst-body-gray-02, #d1d1d1);
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 18px;
}

.buttomForm {
  display: flex;
  align-items: center;
  margin-top: 15px;
}

.buttomForm p {
  margin-bottom: 0;
  width: 60px;
}

.buttomForm input {
  width: calc(100% - 60px);
  border-radius: 6px;
  border: 1px solid var(--CTA-orange, #f0a139);
  background: #fff;
  height: 30px;
  padding: 20px 10px;
}

.yellowButton {
  display: flex;
  cursor: pointer;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 100px;
  border: 1px solid var(--CTA-orange, #f0a139);
  color: var(--CTA-orange, #f0a139);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  background: transparent;
  letter-spacing: -0.28px;
}

.yellowButton:hover {
  background: #f0a139;
  color: white;
}
.yellowButton:hover svg path {
  fill: white;
}

.innerForm .yellowButton {
  position: absolute;
  bottom: -70px;
  right: 0;
}
.inputTypical {
  width: calc(100% - 60px);
  border-radius: 6px;
  border: 1px solid var(--CTA-orange, #f0a139);
  background: #fff;
  height: 30px;
  padding: 20px 10px;
}
.inputTypical:focus {
  outline: 2px solid #f0a139;
  border-radius: 6px;
  border: 1px solid var(--CTA-orange, #f0a139);
  background: var(--tekst-body-gray-03, #f7f7f7);
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.1),
    0px 11px 11px 0px rgba(0, 0, 0, 0.09), 0px 24px 14px 0px rgba(0, 0, 0, 0.05),
    0px 42px 17px 0px rgba(0, 0, 0, 0.01), 0px 66px 19px 0px rgba(0, 0, 0, 0);
}

.circleImageClassic img {
  height: 80px;
  width: 80px;
  object-fit: cover;
  border-radius: 50%;
}
.squireImage img {
  height: 80px;
  width: 80px;
  object-fit: cover;
}

.circleImageClassic img:hover,
.squireImage img:hover {
  transition: all 0.2s ease-in-out;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1),
    0px 9px 9px 0px rgba(0, 0, 0, 0.09), 0px 20px 12px 0px rgba(0, 0, 0, 0.05),
    0px 35px 14px 0px rgba(0, 0, 0, 0.01), 0px 55px 15px 0px rgba(0, 0, 0, 0);
}

.new {
  border-radius: 15px;
  background: var(--CTA-orange, #f0a139);
  color: var(--background-gray-00, #f8f8f8);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 154%; /* 15.4px */
  letter-spacing: -0.4px;
  width: fit-content;
  padding: 2px 8px;
}

.nameTd p {
  margin-top: 10px;
  color: var(--tekst-display-gray-01, #2f2a2a);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 154%; /* 21.56px */
  letter-spacing: -0.56px;
}

.dateTable {
  color: var(--tekst-display-gray-01, #2f2a2a);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 154%; /* 18.48px */
  letter-spacing: -0.48px;
}

.table td {
  vertical-align: middle;
}

.gridDetail {
  display: flex;
  flex-direction: row;
  align-items: start;
  row-gap: 10px;
  column-gap: 10px;
  flex-wrap: wrap;
}

.gridDetail .gridItemProject {
  width: calc(20% - 8px);
  position: relative;
}

.gridNotes textarea {
  width: 100%;
  border: 1px solid #777;
  border-radius: 4px;
}
.gridNotes textarea:focus {
  outline: none;
  box-shadow: none;
  border: 1px solid #777;
}

.gridDetail .gridItemProject .topItem img {
  border-radius: 8px;
  object-fit: cover;
  height: 300px;
}

.gridDetail .gridItemProject .new {
  position: absolute;
  top: 20px;
  left: 20px;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  border-radius: 4px;
}

.gridDetail .gridItemProject p {
  margin-bottom: 0;
  color: var(--tekst-display-gray-01, #2f2a2a);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 21.56px */
  letter-spacing: -0.56px;
  margin-bottom: 0;
  margin-top: 10px;
  padding-bottom: 5px;
  border-bottom: 1px solid var(--tekst-body-gray-02, #d1d1d1);
}

.iconsBottom {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 6px;
  padding: 10px;
}
.bottomNotesImage {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: var(--tekst-display-gray-01, #2f2a2a);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 154%; /* 18.48px */
  letter-spacing: -0.48px;
}

.neworange,
.nameTd .neworange {
  width: fit-content;
  padding: 2px 8px;
  border-radius: 10px;
  color: var(--background-gray00, #f8f8f8) !important;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 154%; /* 15.4px */
  letter-spacing: -0.4px;
  background: var(--CTA-orange, #f0a139);
}

.bottomNotesImage .icons {
  display: flex;
  gap: 6px;
}
.swiperListing.poprawki .topUklad {
  min-height: 330px;
}
#dektopSwiperListing .topUklad#active {
  border: 2px solid #000;
}
#dektopSwiperListing .topUklad {
  height: 20vh;
  overflow: hidden;
}
.noteAuthor {
  margin-bottom: 0;
  margin-top: 5px;
  font-weight: 400;
  color: #777;
}
.answerNote button.toggle {
  margin-top: 10px;
  padding: 4px 12px;
  border-radius: 100px;
  border: 1px solid var(--tekst-display-gray-01, #2f2a2a);
  color: var(--tekst-display-gray-01, #2f2a2a);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  letter-spacing: -0.32px;
  background: white;
}
.bottomNotesImage .icons .icon {
  width: 32px;
  height: 32px;
  border: 1px solid #000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.iconsBottom .icon {
  display: flex;
  cursor: pointer;
  width: 32px;
  height: 32px;
  padding-bottom: 2px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border: 1px solid #000;
}

.imagePinnerCoint img {
  width: 100%;
}

.imagePinnerCoint {
  position: relative;
}
.parentMagnifier {
  height: 100%;
}

.parentMagnifier div:first-child > img {
  max-width: 100% !important;
  height: auto !important;
}
.parentMagnifier {
  display: flex;
  justify-content: center;
}
.imageMagnifierWrapper {
  z-index: 9999999;
  position: fixed;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: 10vh;
  text-align: center;
  width: 1000px;
  margin-left: auto;
  max-width: 90vw;
  height: 80vh;
  max-height: 90vh;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}
.imageMagnifierWrapper div {
  height: 100%;
  overflow: hidden;
}
.magni.iconClose {
  position: absolute;
  top: 20px;
  right: 20px;
  color: white;
  font-size: 25px;
  text-align: end;
  z-index: 999999;
}
.imagePinnerCoint .pointer {
  position: absolute;
  background: #f0a139;
  width: 24px;
  height: 24px;
  border-radius: 50% 50% 50% 0;
  display: flex;
  align-items: center;
  color: var(--background-gray00, #f8f8f8);
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 154%; /* 18.48px */
  letter-spacing: -0.24px;
  justify-content: center;
}
.imagePinnerCoint .pointer.new {
  background: white;
  border: 3px solid #f0a139;
}
.gridItemProject .ItemAdd {
  border-radius: 8px;
  border: 1px solid var(--CTA-orange, #f0a139);
  background: #fff;
  aspect-ratio: 1/1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  gap: 0;
}

.gridItemProject .ItemAdd p {
  margin-bottom: 0;
  border-bottom: none;
}

.notes {
  border-radius: 6px;
  overflow-y: auto;
  max-height: 65vh;
  display: flex;
  padding: 12px 10px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  flex-shrink: 0;
  background: #fff;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.1),
    0px 1px 3px 0px rgba(0, 0, 0, 0.1), 0px 6px 6px 0px rgba(0, 0, 0, 0.09),
    0px 13px 8px 0px rgba(0, 0, 0, 0.05), 0px 24px 10px 0px rgba(0, 0, 0, 0.01),
    0px 37px 10px 0px rgba(0, 0, 0, 0);
}
.notes .top {
  width: 100%;
}
.notes .top p {
  padding: 10px 0;
  margin-bottom: 0;
  color: var(--tekst-display-gray-01, #2f2a2a);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 154%; /* 21.56px */
  letter-spacing: -0.28px;
  padding: 10px;
  width: 100%;
  text-transform: uppercase;
  border-bottom: 0.7px solid #d6d6d6;
}
.note .noteTop {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 0.7px solid #d6d6d6;
}
.note .noteTop p {
  margin-bottom: 0;
  color: var(--tekst-display-gray-01, #2f2a2a);
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 154%; /* 18.48px */
  letter-spacing: -0.24px;
}

.note .noteTop > div {
  align-items: center;
  padding: 10px;
}

.note .noteTop p.date {
  color: var(--tekst-body-gray-02, #d1d1d1);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 154%; /* 18.48px */
  letter-spacing: -0.48px;
}

.note div.content {
  color: var(--tekst-display-gray-01, #2f2a2a);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 154%; /* 18.48px */
  letter-spacing: -0.48px;
  padding: 10px;
  word-break: break-all;
}
.wrapperNotifications .projectName {
  font-size: 20px;
  padding: 10px;
  font-weight: 600;
}
.wrapperNotifications .table {
  margin-bottom: 0;
}
.note div.content a {
  color: var(--tekst-display-gray-01, #2f2a2a);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 154%; /* 18.48px */
  letter-spacing: -0.48px;
}

button.black {
  display: flex;
  padding: 12px 24px 13px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background: transparent;
  border-radius: 100px;
  text-decoration: none;
  border: 1px solid var(--tekst-display-gray-01, #2f2a2a);
}

.noDecor,
.noDecor a {
  text-decoration: none;
}
svg {
  cursor: pointer;
}
.mainTD {
  color: var(--CTA-orange, #f0a139);
  font-size: 16px;
  font-style: normal;
  padding: 5px 0;
  font-weight: 600;
  line-height: 154%; /* 21.56px */
  letter-spacing: -0.56px;
  text-transform: uppercase;
}

.lastTR small {
  color: var(--CTA-orange, #f0a139);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 154%; /* 21.56px */
  letter-spacing: -0.56px;
}
.lastTR td div {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: center;
  gap: 5px;
}
.lastTR td p {
  font-size: 20px;
  color: var(--CTA-orange, #f0a139);
}

.classicTable th p.yellow {
  margin-bottom: 0;
  color: var(--CTA-orange, #f0a139);
  font-style: normal;
  font-weight: 600;
  line-height: 154%; /* 21.56px */
  letter-spacing: -0.56px;
  text-transform: uppercase;
}

.classicTable th p.yellowBlack {
  margin-bottom: 0;
  font-style: normal;
  color: black;
  font-weight: 600;
  line-height: 154%; /* 21.56px */
  letter-spacing: -0.56px;
  text-transform: uppercase;
}
.podsumowanieTable {
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  background: white;
}

.podsumowanieTable p {
  color: var(--tekst-display-gray-01, #2f2a2a);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 154%; /* 27.72px */
  letter-spacing: -0.36px;
}

.podsumowanieTable strong {
  color: var(--tekst-display-gray-01, #2f2a2a);
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 154%;
  letter-spacing: -0.44px;
}

.yellowBG {
  background-color: #f0a139;
}
.rooms {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.podsumowanieTable.yellowBG p,
.podsumowanieTable.yellowBG strong {
  color: white;
}
.podsumowanieTable.yellowBG strong {
  font-size: 32px;
}
.podsumowanieTable strong {
  padding-left: 10px;
}
.pomieszczenie div.room {
  width: fit-content;
  padding: 10px;
  background: #2f2a2a;
  cursor: pointer;
  border-radius: 10px;
}
.smallDelete {
  border: none;
  background: #f0393928;
  border-radius: 10px;
  color: #f03939;
}
.circleImage {
  border-radius: 50%;
  width: 80px;
  height: 80px;
}

.classicTable td p {
  margin-bottom: 0;
}

.poprawkaImage {
  width: 50%;
  margin: auto;
}

.invisibleInput {
  border: none;
}
.invisibleInput {
  background: transparent;
}
.invisibleInput:focus {
  outline: none;
}

.notes .gridNotes {
  width: 100%;
}
.gridNotes p.answers {
  margin-bottom: 0;
  padding: 0.5rem 0;
  color: var(--tekst-display-gray-01, #2f2a2a);
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 154%;
  letter-spacing: -0.24px;
  border-top: 0.7px solid #d6d6d6;
  border-bottom: 0.4px solid #d6d6d670;
}
.answersGrid .content {
  border-bottom: 0.4px solid #d6d6d670;
}
.yellowButton svg {
  font-size: 24px;
}

#singlePoprawka {
  padding-bottom: 30px;
  border-bottom: 1px solid var(--tekst-body-gray-02, #d1d1d1);
}

.weight600 {
  font-weight: 600;
}

.weight500 {
  font-weight: 500;
}

.smallerDate {
  color: var(--tekst-display-gray-01, #2f2a2a);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 154%; /* 18.48px */
  letter-spacing: -0.48px;
}

.smallerDate strong {
  font-size: 18px;
  padding-left: 10px;
}

.borderTopUnderTable {
  padding-left: 20px;
  border-top: 1px solid var(--tekst-body-gray-02, #d1d1d1);
  color: var(--tekst-display-gray-01, #2f2a2a);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.borderTopUnderTable strong {
  color: var(--tekst-display-gray-01, #2f2a2a);
  font-size: 16px;
  font-weight: 700;
}

.materialyBottom {
  color: var(--tekst-display-gray-01, #fff);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-left: 20px;
  display: flex;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
}
.materialyBottom p {
  margin-bottom: 0;
}
.materialyBottom input {
  width: 240px;
  padding: 10px;
  border: 1px solid var(--tekst-body-gray-02, #d1d1d1);
}
.materialyBottom input:focus {
  outline: none;
}

.bottomTextDoc {
  color: var(--tekst-display-gray-01, #2f2a2a);
  text-align: center;
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.38px;
}

.classicTable tr:hover td {
  background: var(--tekst-body-gray-03, #f7f7f7);
}
.buttons a {
  text-decoration: none;
}
.gridDocuments {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 10px;
  row-gap: 10px;
  padding: 20px;
}

.gridDocuments .documentBlock {
  width: 20%;
  cursor: pointer;
  border-radius: 6px;
  border: 1px solid var(--CTA-orange, #f0a139);
  background: #fff;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 200px;
}
.gridDocuments .documentBlock > svg {
  margin-top: 30px;
}

.gridDocuments .documentBlock .number {
  position: absolute;
  top: 5px;
  left: 5px;
  height: 30px;
  width: 30px;
  border-radius: 4px;
  display: flex;
  color: var(--tekst-body-gray-03, #f7f7f7);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 154%; /* 21.56px */
  letter-spacing: -0.56px;
  align-items: center;
  justify-content: center;
  color: white;
  background: var(--CTA-orange, #f0a139);
}

.gridDocuments .documentBlock .iconsBlock {
  position: absolute;
  top: 5px;
  right: 5px;
  display: flex;
  align-items: row;
  gap: 10px;
}
.innerForm .innerFlexFunkc#emails {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  flex-wrap: wrap;
}
.gridDocuments .documentBlock > p.name {
  color: var(--tekst-display-gray-01, #2f2a2a);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  margin-top: 10px;
  margin-bottom: 0;
  font-weight: 500;
  line-height: 120%; /* 16.8px */
  letter-spacing: -0.56px;
}

#komiLeft {
  padding: 0 10px 0 20px;
}

.contactsGrid {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.contactsGrid .contact {
  display: flex;
  cursor: pointer;
  flex-direction: row;
  gap: 10px;
}
#komiRight {
  border-bottom: 1px solid var(--tekst-body-gray02, #d1d1d1);
  background: var(--background-gray00, #f8f8f8);
  position: relative;
}

.iconFile {
  position: absolute;
  bottom: 10px;
  left: 20px;
}

.iconImage {
  position: absolute;
  bottom: 10px;
  left: 60px;
}
.filesIcon {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 10px;
}
.filesIcon .previewFile {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: #f8f8f8;
  border-radius: 50px;
  width: fit-content;
  padding: 6px;
}
.indexKomunikator.filesIcon .previewFile {
  background: #ebebeb;
}
.previewFile .fileName {
  width: 200px;
  overflow: hidden;
  font-size: 16px;
  word-break: keep-all;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.indexKomunikator .ck.ck-content {
  min-height: 320px;
}
.previewFile .removeFile {
  color: red;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.previewFile .iconPrint {
  position: absolute;
  bottom: 10px;
  right: 40px;
}
.newMessage .messageFrom div svg {
  margin-right: 40px;
}

#komiLeft .topSection,
#komiRight .topSection {
  height: 80px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: var(--tekst-display-gray-01, #2f2a2a);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 19.2px */
  letter-spacing: -0.32px;
}

#komiLeft .topSection {
  border-bottom: 1px solid var(--tekst-body-gray02, #d1d1d1);
}
#komiRight .topSection {
  border-bottom: 1px solid var(--tekst-body-gray02, #d1d1d1);
}

#komiLeft {
  border-right: 1px solid var(--tekst-body-gray02, #d1d1d1);
  border-bottom: 1px solid var(--tekst-body-gray02, #d1d1d1);
  border-left: 1px solid var(--tekst-body-gray02, #d1d1d1);
  background: var(--tekst-body-gray03, #f7f7f7);
}

.subject {
  border-bottom: 1px solid var(--tekst-body-gray02, #d1d1d1);
  background: #f8f8f8;
}

.messageFrom {
  border-bottom: 1px solid var(--tekst-body-gray02, #d1d1d1);
  background: #f8f8f8;
}

.newMessageText {
  background: var(--background-gray01, #ececec);
}

.sortPoczta {
  border-bottom: 1px solid var(--tekst-body-gray02, #d1d1d1);
  background: var(--background-orange01, #f9f1e8);
}

.newMessage input,
.newMessage textarea {
  background: transparent;
}

.bottomButtonsKomu {
  display: flex;
  flex-direction: column;
}
.bottomButtonsKomu .leftButton {
  border-bottom: 1px solid var(--tekst-body-gray02, #d1d1d1);
}
.bottomButtonsKomu .leftButton button,
.buttonFullYellow,
button.createThread {
  border-radius: 100px;
  background: var(--CTA-orange, #f0a139);
  display: flex;
  padding: 12px 24px;
  justify-content: center;
  color: var(--tekst-body-gray03, #f7f7f7);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 16.8px */
  letter-spacing: -0.28px;
  align-items: center;
  border: none;
  gap: 8px;
}
.messageContent,
.ck-toolbar__items,
.ck.ck-toolbar {
  background: #f8f8f8 !important;
}

.ck-toolbar__items,
.ck.ck-button,
a.ck.ck-button,
.ck.ck-toolbar {
  border: none !important;
}
.ck.ck-content {
  min-height: 200px;
  box-shadow: none !important;
  border: none !important;
  background: transparent !important;
}
button.createThread {
  position: absolute;
  bottom: 10px;
  right: 20px;
}

.bottomButtonsKomu .rightButton {
  display: flex;
  justify-content: flex-end;
  padding-right: 10px;
}
.bottomButtonsKomu .rightButton button {
  border-radius: 100px;
  border: 1px solid var(--CTA-orange, #f0a139);
  display: flex;
  background: transparent;
  padding: 12px 24px 13px 24px;
  justify-content: center;
  align-items: center;
  gap: 6px;
}

.newMessage .invisibleInput ::placeholder {
  color: black;
  opacity: 1; /* Firefox */
}

.newMessage .invisibleInput ::-ms-input-placeholder {
  /* Edge 12 -18 */
  color: black;
}

.newMessageText p {
  color: var(--tekst-display-gray-01, #2f2a2a);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 19.2px */
  letter-spacing: -0.32px;
}

.messageFrom p {
  color: var(--tekst-display-gray-01, #2f2a2a);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.32px;
}

.subject input {
  color: var(--tekst-display-gray-01, #2f2a2a);
  font-size: 16px;
  font-style: normal;
  opacity: 1;
  font-weight: 600;
  line-height: 120%; /* 19.2px */
  letter-spacing: -0.32px;
}

.threadBlock {
  display: flex;
  flex-direction: column;
}

.threadBlock .thread {
  display: flex;
  flex-direction: column;
  gap: 2px;
  text-align: left;
  justify-content: center;
  border-bottom: 1px solid var(--tekst-body-gray02, #d1d1d1);
  background: #f8f8f8;
  padding-left: 1rem;
  min-height: 70px;
  cursor: pointer;
}
.threadBlock .thread:hover {
  border-bottom: 1px solid var(--tekst-body-gray02, #d1d1d1);
  background: var(--background-orange01, #f9f1e8);
}
.threadBlock .thread h6 {
  color: var(--tekst-display-gray-01, #2f2a2a);
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 19.2px */
  letter-spacing: -0.32px;
}

.threadBlock .thread p {
  color: var(--tekst-body-gray-04, #bfbfbf);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 14.4px */
  letter-spacing: -0.24px;
}

.adminContent .topSection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 0;
  padding-right: 40px;
}

.adminContent .inputWrapper {
  position: relative;
  max-width: 100%;
  width: 400px;
}
.adminContent .todolist .inputWrapper {
  max-width: 250px;
  width: 90vw;
}

.clientContent .outlineGrey {
  background: transparent;
}
.clientContent .outlineGrey:hover {
  background: #2f2a2a;
  color: white;
}
.adminContent .inputWrapper svg {
  position: absolute;
  top: 25%;
  right: 15px;
}
.adminContent .inputWrapper input {
  border-radius: 6px;
  border: 1px solid var(--tekst-body-gray02, #d1d1d1);
  background: #fff;
  height: 40px;
  padding: 20px 10px;
  width: 100%;
}
.adminContent .inputWrapper input:focus {
  outline: none;
}
.adminContent .topSection .buttons {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.adminContent .topSection {
  border-bottom: 1px solid var(--tekst-body-gray02, #d1d1d1);
  background: var(--tekst-body-gray03, #f7f7f7);
}
.adminContent .todolist.topSection {
  border-bottom: none;
}

.clientContent {
  display: flex;
  flex-direction: column;
}

.clientContent .top {
  padding: 20px 0 10px;
  display: flex;
  flex-direction: row;
  width: 100%;
  border-bottom: 1px solid var(--tekst-body-gray02, #d1d1d1);
  background: var(--background-orange01, #f9f1e8);
}

.clientContent .top#notActive {
  border-bottom: 1px solid var(--tekst-body-gray02, #bcb8b1);
  background: var(--background-orange01, #bcb8b1);
  color: white;
}

.clientContent .top .leftNumber {
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--tekst-body-gray02, #d1d1d1);
  font-size: 19px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 22.8px */
  letter-spacing: -0.38px;
}
.clientContent .top .mainTop {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: calc(100% - 80px);
  padding-right: 40px;
}

.clientContent .top .mainTop .left {
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  align-items: flex-start;
}

.clientContent .top .mainTop .left h6 {
  color: var(--tekst-display-gray-01, #2f2a2a);
  font-size: 19px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 22.8px */
  letter-spacing: -0.38px;
}
.clientContent .top .mainTop .left p {
  color: var(--tekst-body-gray02, #232323);
  font-size: 12px;
  margin-top: 5px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 14.4px */
  letter-spacing: -0.24px;
}
.clientContent .top .mainTop .right {
  display: flex;
  align-items: center;
  gap: 20px;
}

.isOpenContent {
  overflow: hidden;
}

.isOpenContent#notActive {
  max-height: 0px;
  height: 0px;
  background: #fff;
  transition: max-height 0.5s ease-in-out;
}
.isOpenContent#active {
  height: fit-content;
  background: #fff;
  max-height: 2000px;
  transition: max-height 0.5s ease-in-out;
}
.paddingLeft {
  padding-left: 80px;
  border-bottom: 1px solid #d1d1d1;
}
.contentOpen {
  background: #fff;
  /* padding-left: 80px; */
  border-left: 1px solid var(--tekst-body-gray02, #d1d1d1);
}

.contentOpen .client,
.contentOpen .wykonawca {
  color: var(--tekst-display-gray-01, #2f2a2a);
  font-size: 19px;
  font-style: normal;
  padding: 10px;
  font-weight: 600;
  line-height: 120%; /* 22.8px */
  letter-spacing: -0.38px;
  border-bottom: 1px solid var(--tekst-body-gray02, #d1d1d1);
}
.wrapStangeButtons {
  padding: 5px;
  border-bottom: 1px solid var(--tekst-body-gray02, #d1d1d1);
}
.gridFirstData {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-left: 0;
}

.gridFirstData .street {
  width: 35%;
  border: none;
  border-right: 1px solid var(--tekst-body-gray02, #d1d1d1);
  border-bottom: 1px solid var(--tekst-body-gray02, #d1d1d1);
}
.gridFirstData .city {
  width: 20%;
  border: none;
  border-right: 1px solid var(--tekst-body-gray02, #d1d1d1);
  border-bottom: 1px solid var(--tekst-body-gray02, #d1d1d1);
}

.gridFirstData.short .Email {
  border-bottom: none;
}
.gridFirstData.short .mobile {
  border-right: none;
  border-bottom: none;
}
.gridFirstData .dzielnica {
  width: 20%;
  border: none;
  border-bottom: 1px solid var(--tekst-body-gray02, #d1d1d1);
}
.wrapStangeButtons {
  width: 25%;
}
.stangeButtons {
  padding: 2px;
  display: flex;
  flex-direction: row;
  gap: 4px;
  border-radius: 100px;
  border: 1px solid var(--tekst-body-gray02, #d1d1d1);
  background: var(--background-gray00, #f8f8f8);
}
.gridFirstData .stangeButtons {
  width: fit-content;
}

.stangeButtons button {
  border-radius: 100px;
  color: var(--tekst-display-gray-01, #2f2a2a);
  text-align: center;
  font-size: 19px;
  border: none;
  padding: 12px 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 22.8px */
  letter-spacing: -0.38px;
  background: transparent;
  cursor: pointer;
}
.stangeButtons button#active {
  background: var(--CTA-orange, #f0a139);
  color: var(--background-gray00, #f8f8f8);
  text-align: center;
  font-size: 19px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 22.8px */
  letter-spacing: -0.38px;
  transition: background 0.2s ease-in-out;
}
.gridFirstData input {
  background: transparent;
  padding: 10px;
}
.gridFirstData input:focus {
  outline: none;
}
.gridFirstData input::placeholder {
  color: var(--tekst-body-gray02, #d1d1d1);
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 22.8px */
  letter-spacing: -0.38px;
}

.gridFirstData .Name {
  width: 22%;
  border: none;
  border-right: 1px solid var(--tekst-body-gray02, #d1d1d1);
  border-bottom: 1px solid var(--tekst-body-gray02, #d1d1d1);
}
.gridFirstData .Surname {
  width: 33%;
  border: none;
  border-right: 1px solid var(--tekst-body-gray02, #d1d1d1);
  border-bottom: 1px solid var(--tekst-body-gray02, #d1d1d1);
}

.gridFirstData .Email {
  width: 22%;
  border: none;
  border-right: 1px solid var(--tekst-body-gray02, #d1d1d1);
  border-bottom: 1px solid var(--tekst-body-gray02, #d1d1d1);
}
.gridFirstData .mobile {
  width: 23%;
  border: none;
  border-right: 1px solid var(--tekst-body-gray02, #d1d1d1);
  border-bottom: 1px solid var(--tekst-body-gray02, #d1d1d1);
}

.addSpace,
.contentOpen .addWykonawca {
  color: var(--tekst-display-gray-01, #2f2a2a);
  font-size: 19px;
  font-style: normal;
  padding: 10px;
  font-weight: 600;
  display: flex;
  gap: 5px;
  align-items: center;
  line-height: 120%;
  letter-spacing: -0.38px;
  border-bottom: 1px solid var(--tekst-body-gray02, #d1d1d1);
}

.pomieszczenie {
  display: flex;
  justify-content: space-between;
  color: var(--tekst-body-gray02, #d1d1d1);
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  align-items: center;
  line-height: 120%; /* 22.8px */
  letter-spacing: -0.38px;
  padding: 10px;
  border-bottom: 1px solid var(--tekst-body-gray02, #d1d1d1);
}

.pomieszczenie input {
  border-radius: 6px;
  border: 1px solid var(--tekst-body-gray02, #d1d1d1);
  background: #fff;
  color: var(--tekst-body-gray02, #000);
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.38px;
  padding: 10px;
}

.pomieszczenie div {
  width: 40%;
  gap: 10px;
}
.pomieszczenie div input {
  width: calc(100% - 110px);
}
.pomieszczenie div button {
  width: 100px;
  border-radius: 100px;
  background: var(--CTA-orange, red);
  display: flex;
  padding: 12px 24px;
  justify-content: center;
  color: var(--tekst-body-gray03, #f7f7f7);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  letter-spacing: -0.28px;
  align-items: center;
  border: none;
  gap: 8px;
}

.gridDataWykonawca {
  display: flex;
  flex-wrap: wrap;
}
.gridDataWykonawca div {
  position: relative;
}
.suggestions-list {
  display: none;
  position: absolute;
  z-index: 9;
  background: #f0a139;
  top: 100%;
  left: 0;
  right: 0;
  border: 1px solid #ccc;
  list-style: none;
  padding: 0;
  margin: 0;
}

.suggestions-list li {
  padding: 8px;
  color: white;
  cursor: pointer;
}

.imageWykonawcaDesktop {
  max-width: 300px;
  max-height: 200px;
  object-fit: cover;
  border-radius: 10px;
}

.suggestions-list li:hover {
  background-color: #df8e25;
  color: white;
}
.gridDataWykonawca .wName,
.gridDataWykonawca .wSurname,
.gridDataWykonawca .wMobile {
  width: 40%;
}
.gridDataWykonawca div:hover .suggestions-list {
  display: block;
}
.gridDataWykonawca div input {
  width: 100% !important;
  border: none;
  padding: 10px;
  border-right: 1px solid var(--tekst-body-gray02, #d1d1d1);
  border-bottom: 1px solid var(--tekst-body-gray02, #d1d1d1);
}
.gridDataWykonawca .wFunction {
  width: 20%;
}

.gridDataWykonawca input:focus {
  outline: none;
}

.gridDataWykonawca .wEmail {
  width: calc(60% - 110px);
}

.mobileWykonawcy .topSec {
  border-top: 1px solid var(--tekst-body-gray02, #d1d1d1);
  border-bottom: 1px solid var(--tekst-body-gray02, #d1d1d1);
  background: var(--background-orange01, #f9f1e8);
  padding: 10px 20px;
}

.gridDataWykonawca button,
.client button,
.gridFirstData.short button {
  width: 100px;
  border-radius: 100px;
  background: var(--CTA-orange, red);
  display: flex;
  padding: 12px 24px;
  justify-content: center;
  color: var(--tekst-body-gray03, #f7f7f7);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  letter-spacing: -0.28px;
  align-items: center;
  border: none;
  gap: 8px;
}
.gridFirstData.short button {
  background: #f0a139;
}
.gridDataWykonawca .buttonWrapper {
  padding-top: 2px;
  border-bottom: 1px solid var(--tekst-body-gray02, #d1d1d1);
}
.adminBottom a {
  text-decoration: none;
}
.bottomButtonsAdd {
  display: flex;
  padding: 10px;
  justify-content: flex-end;
}

.bottomButtonsAdd .stangeButtons {
  width: fit-content;
}

.isOpenContent .yellowButton {
  margin-left: 80px;
}

.bottomButtonWrapere {
  display: flex;
  justify-content: end;
  padding: 10px;
}

.ankietyGrid {
  display: flex;
  flex-direction: column;
}

.ankietyGrid .ankieta {
  display: flex;
  padding: 10px 20px;
  align-items: center;
  gap: 40px;
  cursor: pointer;
  align-self: stretch;
  border-bottom: 1px solid var(--tekst-body-gray02, #d1d1d1);
}

.ankietyGrid .ankieta img {
  width: 80px;
  height: 80px;
}

.ankietyGrid .ankieta h6 {
  color: var(--tekst-display-gray-01, #2f2a2a);
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%; /* 24px */
  letter-spacing: -0.48px;
  margin-bottom: 0;
}

#login .innerLeft {
  max-width: 720px;
  padding: 90px 0;
  margin: auto;
}
#login {
  background: var(--tekst-body-gray03, #f7f7f7);
}
#login .innerLeft h1 {
  color: #2f2a2a;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px; /* 181.818% */
  letter-spacing: -0.44px;
}
#login .innerLeft form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

#login .innerLeft form input {
  height: 60px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 19.2px */
  border: 1px solid var(--tekst-body-gray02, #d1d1d1);
  padding: 10px;
}

#login .innerLeft form input:focus {
  outline: none;
  border: 2px solid var(--tekst-display-gray-01, #2f2a2a);
  background: var(--tekst-body-gray03, #f7f7f7);
}
#login .innerLeft form ::placeholder {
  color: var(--tekst-body-gray02, #d1d1d1);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 19.2px */
}
#login .innerLeft form button {
  background: var(--CTA-orange, #f0a139);
  color: var(--tekst-body-gray03, #f7f7f7);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 16.8px */
  border: none;
  padding: 12px 24px;
}
#login a {
  color: black;
  margin-top: 1rem;
  text-decoration: none;
}
.checkBoxes {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.checkBoxes .checking {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
}
.checkBoxes .checking button {
  width: 20px;
  border-radius: 4px;
  border: 1px solid var(--tekst-display-gray-01, #2f2a2a);
  height: 20px;
}
.checkBoxes .checking button#active {
  background: #f0a139;
  border-color: #f0a139;
}

.checkBoxes .checking p {
  margin-bottom: 0;
}

#login .bgImage {
  height: 100vh;
  object-fit: cover;
}

.shoppingListGrid {
  display: flex;
  flex-direction: column;
}

.shoppingListItem {
  display: flex;
  flex-direction: column;
}
.shoppingListItem#active .greyButtonPtaszek {
  transform: rotate(180deg);
  transition: all 0.5s ease-in-out;
}

.shoppingListItem#notActive .greyButtonPtaszek {
  transform: rotate(0deg);
  transition: all 0.5s ease-in-out;
}

.shoppingListItem .objectItem {
  display: flex;
  position: relative;
  flex-direction: row;
  padding: 10px 20px;

  align-items: center;
  border-bottom: 1px solid #2f2a2a;
}

.shoppingListItem .objectItem form {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.shoppingListItem .objectItem form button {
  height: 42px;
  background: #f0a139;
  border: none;
  color: white;
  font-size: 20px;
  width: 43px;
}
.shoppingListItem .objectItem form input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.statusMobile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.statusName {
  margin-bottom: 0;
  font-size: 14px;
}
.orderInput {
  max-width: 30px;
}

.objectItem .order {
  position: absolute;
  top: 10px;
  left: 10px;
}

.shoppingListItem h1 {
  font-size: 24px;
  letter-spacing: 1px;
  color: #2f2a2a;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0;
}
.shoppingListItem#notActive .roomShoppingList {
  display: none;
}
.shoppingListItem#active .roomShoppingList {
  display: block;
  background: #fff;
}
.shoppingListItem#active .topShoppingList {
  background: #f0a139;
  color: white;
}
.shoppingListItem#active .topShoppingList h1,
.shoppingListItem#active .topShoppingList svg,
.shoppingListItem#active .topShoppingList svg rect,
.shoppingListItem#active .topShoppingList svg path {
  color: white;
  stroke: white !important;
}

.topShoppingList {
  cursor: pointer;
  border-bottom: 2px solid #2f2a2a;
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.topNavigationDetail {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}
.changeImage {
  border-radius: 100px;
  background: var(--CTA-orange, #f0a139);
  padding: 12px 24px;
  color: var(--background-gray00, #f8f8f8);
  text-align: center;
  font-family: "Libre Franklin";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 16.8px */
  border: none;
  letter-spacing: -0.28px;
}
.topNavigationDetail .icon {
  border: 1px solid black;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.zoomImage {
  width: 100%;
  overflow: scroll;
  max-height: 65vh;
}

.zoomImage.zoomType1 .wartstwa {
  width: 100%;
}

.zoomImage.zoomType2 .wartstwa {
  width: 150%;
}
.zoomImage.zoomType3 .wartstwa {
  width: 200%;
}
.zoomImage.zoomType4 .wartstwa {
  width: 250%;
}
.zoomImage.zoomType5 .wartstwa {
  width: 300%;
}

.wartstwa {
  position: relative;
}

.ImagerightSideButtons button {
  height: 40px;
  padding: 0 20px;
  line-height: normal;
}

.deliveryTruck {
  font-size: 24px;
  width: 20px;
}
.editDots.redBG {
  background: red;
  color: white;
}
.shoppingListItem h1 .counter {
  margin-left: 20px;
  font-size: 13px;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
}
.shoppingListGrid .objectItem .imageLeft {
  width: 20%;
  padding: 20px;
}

.shoppingListGrid .objectItem .center {
  width: 40%;
}

.shoppingListGrid .objectItem .center h2 {
  font-size: 18px;
  font-weight: 500;
  max-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: 20px;
}

.shoppingListGrid .objectItem .center a {
  font-size: 15px;
  color: #2f2a2a;
}

.shoppingListGrid .objectItem .right {
  width: 40%;
}

.editDots {
  width: 24px;
  cursor: pointer;
  height: 24px;
  border-radius: 50%;
  background-color: #5555554f;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.editDots .dropdownSettings {
  position: absolute;
  top: 30px;
  background: white;
  width: 200px;
  border: 1px solid #2f2a2a46;
  z-index: 99;
  border-radius: 8px;
}
.dropdownSettings .item {
  padding: 8px 10px;
  width: 100%;
  text-align: left;
  cursor: pointer;
}
.dropdownSettings.budzet .item {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  font-size: 15px;
}
.dropdownSettings .item .smallCircle {
  width: 15px;
  height: 15px;
  border-radius: 50%;
}
.dropdownSettings .item:hover {
  background: #2f2a2a46;
  color: white;
}
.shoppingListGrid .objectItem .right p {
  margin-bottom: 5px;
  font-size: 14px;
  color: black;
  font-weight: 400;
}
.shoppingListGrid .objectItem .right p strong {
  font-size: 16px;
  font-weight: 600;
}
.shoppingListGrid .objectItem .right #icons {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 12px;
}

.landingpage #topSidebar {
  border-bottom: 1px solid var(--background-gray00, #f8f8f8);
  background: var(--background-gray00, #f8f8f8);
}

.landingpage #sidebar {
  background: var(--background-gray00, #f8f8f8);
  align-items: center;
  display: flex;
  padding: 20px !important;
  justify-content: center;
}
.landingpage .menuHeader h1 {
  margin-bottom: 0;
  color: var(--tekst-display-gray-01, #2f2a2a);
  font-size: 52px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: -2.08px;
}

.messageWrapper {
  border-bottom: 1px solid var(--tekst-body-gray02, #d1d1d1);
  background: #fff;
  padding-top: 20px;
  padding-bottom: 30px;
}

.messageWrapper .top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.messageWrapper .top div {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 20px;
}

.messageWrapper .top div strong {
  color: var(--tekst-display-gray-01, #2f2a2a);
  font-size: 19px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 22.8px */
  letter-spacing: -0.38px;
}

.messageWrapper .top div p {
  color: var(--tekst-display-gray-01, #2f2a2a);
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: -0.38px;
}

.messageWrapper .top div.date,
#newMess .date {
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 16.8px */
  letter-spacing: -0.28px;
}

.messageWrapper .title {
  color: #000;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 33.6px */
  letter-spacing: -0.56px;
}

.messageWrapper .content {
  padding-top: 40px;
  white-space: pre-wrap;
  color: #000;
  font-family: "Libre Franklin";
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 30.4px */
  letter-spacing: -0.38px;
}

.landingpage .sendMessage {
  text-align: center;
  padding: 40px 0;
}

.landingpage .sendMessage p {
  color: var(--tekst-display-gray-01, #2f2a2a);
  font-family: "Libre Franklin";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 16.8px */
  letter-spacing: -0.28px;
}
.landingpage .sendMessage button {
  border-radius: 100px;
  background: var(--CTA-orange, #f0a139);
  padding: 12px 24px;
  color: var(--background-gray00, #f8f8f8);
  text-align: center;
  font-family: "Libre Franklin";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 16.8px */
  border: none;
  letter-spacing: -0.28px;
}

#newMess #komiLeft {
  border: none;
  border-right: 1px solid var(--tekst-body-gray02, #d1d1d1);
  background: white;
}

#newMess .contactsGrid h3 {
  color: var(--tekst-display-gray-01, #2f2a2a);
  font-family: "Libre Franklin";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 50px; /* 19.2px */
  letter-spacing: -0.32px;
  border-bottom: 1px solid var(--tekst-body-gray02, #d1d1d1);
}

#newMess .newMessage {
  background: white;
}

#newMess .messageFrom {
  background: white;
}

#newMess .subject {
  background: white;
}

.subjectNewMEss {
  color: #000;
  font-family: "Libre Franklin";
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 33.6px */
  letter-spacing: -0.56px;
}

#chat .imageName {
  padding: 20px;
  border: 1px solid var(--tekst-body-gray02, #d1d1d1);
  border-radius: 4px;
}

#chat .images {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.landingpage .toolBar {
  display: flex;
  padding: 20px;
  justify-content: flex-end;
  gap: 20px;
  border-bottom: 1px solid var(--tekst-body-gray02, #d1d1d1);
}

.messageWrapper .title .messageFrom {
  color: var(--tekst-display-gray-01, #2f2a2a);
  font-size: 19px;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.38px;
  font-weight: 400;
  background: white;
}

.messageWrapper .title .messageFrom strong {
  color: var(--tekst-display-gray-01, #2f2a2a);
  font-size: 19px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 22.8px */
  letter-spacing: -0.38px;
}

.ankietaBG {
  background: var(--background-gray02, #f0f0f0);
  padding: 60px 0;
}

.ankietaBG .top {
  padding: 20px 40px;
  background: #f8f8f8;
}

.ankietaBG .top h1 {
  color: var(--tekst-display-gray-01, #2f2a2a);
  font-size: 34px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 0;
  line-height: 150%;
  letter-spacing: -0.68px;
}
.ankietaBG .container {
  background: #f8f8f8;
  padding: 0 20px;
}
.ankietaBG .top h1 strong {
  color: var(--tekst-display-gray-01, #2f2a2a);
  font-size: 34px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 51px */
  letter-spacing: -0.68px;
}

.scrollableSectionChat {
  max-height: 300px;
  overflow: auto;
}

.scrollableSectionChat .question {
  font-weight: 600;
}

.scrollableSectionChat .answer {
  font-size: 15px;
}

.ankietaBG .topCzesc {
  border-radius: 6px;
  background: var(--background-orange01, #f9f1e8);
  padding: 10px 20px;
}
.ankietaBG .topCzesc h2 {
  color: var(--tekst-display-gray-01, #2f2a2a);
  font-size: 24px;
  font-style: normal;
  margin-bottom: 0;
  font-weight: 600;
  padding: 10px 0px;
  line-height: 100%; /* 24px */
  letter-spacing: -0.48px;
}

.ankietaBG .question {
  padding: 20px 20px 10px;
}
.ankietaBG .question h6 {
  margin-bottom: 0;
  color: var(--tekst-display-gray-01, #2f2a2a);
  font-family: "Libre Franklin";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%; /* 20px */
  letter-spacing: -0.4px;
}

.ankietaBG .question h6 span {
  color: var(--tekst-display-gray-01, #2f2a2a);
  font-family: "Libre Franklin";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  letter-spacing: -0.28px;
}

.ankietaBG .answer {
  padding: 10px 40px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}
.ankietaBG .answer p {
  margin-bottom: 0;
  color: var(--tekst-display-gray-01, #2f2a2a);
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Libre Franklin";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.ankietaBG .itemAnkieta {
  padding: 20px 0;
  border-bottom: 0.5px solid #d1d1d6;
}
.answers.grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.answers.images {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  row-gap: 30px;
}
.answers.images .answer {
  width: calc(25% - 10px);
  display: flex;
  flex-direction: column;
  padding: 0;
  align-items: flex-start;
}

.answers.images .answer img.image {
  width: 100%;
  height: 255px;
  object-fit: cover;
  cursor: pointer;
}

.answers.grid .answer {
  width: 40%;
}

.ankietaBG .answer input {
  border-radius: 6px;
  background: #f0f0f0;
  padding: 0px 10px;
  height: 30px;
  outline: none;
  border: none;
  max-width: 70vw;
  width: 300px;
}

.contentHeader {
  padding: 20px;
  margin-top: 10px;
  border-radius: 6px;
  color: var(--tekst-display-gray-01, #2f2a2a);
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%; /* 24px */
  letter-spacing: -0.48px;
  text-transform: uppercase;
  background: var(--background-orange01, #f9f1e8);
}

.resultGraph {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.graph {
  display: flex;
  height: 500px;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  text-align: center;
  width: calc(100% / 13);
}

.graph .colored {
  width: 22px;
  background: var(--background-orange02, #f8e3cc);
  height: 100%;
}
.graph#active .colored {
  background: var(--CTA-orange, #f0a139);
}
.graph .colorWrapper {
  height: 100%;
  display: flex;
  align-items: flex-end;
}
.graph .wrapName {
  min-height: 190px;
  display: flex;
  align-items: center;
}
.graph .name {
  transform: rotate(270deg);
  width: fit-content;
  white-space: nowrap;
  padding: 10px 0;
  border-bottom: 1px solid var(--tekst-body-gray02, #d1d1d1);
  color: var(--tekst-display-gray-01, #2f2a2a);
  font-family: "Libre Franklin";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 36px */
  letter-spacing: -0.48px;
}
.graph#active .name {
  color: var(--CTA-orange, #f0a139);
  font-family: "Libre Franklin";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 36px */
  letter-spacing: -0.48px;
}

.ankietaBG p.style {
  color: var(--tekst-display-gray-01, #2f2a2a);
  font-family: "Libre Franklin";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 36px */
  letter-spacing: -0.48px;
}

.ankietaBG .yourTypeIs {
  border-top: 1px solid var(--tekst-body-gray02, #d1d1d1);
  border-bottom: 1px solid var(--tekst-body-gray02, #d1d1d1);
  background: var(--background-orange01, #f9f1e8);
  padding: 20px 40px;
  color: var(--tekst-display-gray-01, #2f2a2a);
  font-family: "Libre Franklin";
  font-size: 34px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 51px */
  letter-spacing: -0.68px;
}

.ankietaBG .yourTypeIs strong {
  color: var(--tekst-display-gray-01, #2f2a2a);
  font-family: "Libre Franklin";
  font-size: 34px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: -0.68px;
  text-transform: capitalize;
}

.ankietaBG .descH4 {
  border-top: 8px solid var(--background-green01, #1e3c3c);
  background: #f8f8f8;
  color: var(--tekst-display-gray-01, #2f2a2a);
  font-family: "Libre Franklin";
  font-size: 34px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 40.8px */
  letter-spacing: -0.68px;
}

.ankietaBG .descH4 strong {
  color: var(--tekst-display-gray-01, #2f2a2a);
  font-family: "Libre Franklin";
  font-size: 34px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: -0.68px;
}

.ankietaBG .textContent {
  color: #000;
  font-family: "Libre Franklin";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  letter-spacing: -0.32px;
}

.wykonawcaItemMobile {
  border-bottom: 1px solid var(--tekst-body-gray02, #d1d1d1);
  padding: 20px;
  color: var(--tekst-display-gray-01, #2f2a2a);
  font-family: "Libre Franklin";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 154%; /* 24.64px */
  letter-spacing: -0.32px;
}

.wykonawcaItemMobile#active {
  border-bottom: 1px solid var(--tekst-body-gray02, #d1d1d1);
  background: var(--CTA-orange, #f0a139);
  color: white;
}

.zakres {
  display: flex;
  flex-direction: column;
}

.zakres p {
  color: var(--tekst-display-gray-01, #2f2a2a);
  text-align: center;
  font-family: "Libre Franklin";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 16.8px */
  letter-spacing: -0.28px;
  margin-bottom: 0;
}

.zakres small {
  color: var(--tekst-body-gray-04, #bfbfbf);
  font-family: "Libre Franklin";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: -0.24px;
}

.bottomSec div {
  border-bottom: 1px solid var(--tekst-body-gray02, #d1d1d1);
  background: var(--background-gray00, #f8f8f8);
  padding: 10px 20px;
}

.bottomSec div p {
  margin-bottom: 0;
  color: var(--tekst-display-gray-01, #2f2a2a);
  font-family: "Libre Franklin";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 16.8px */
  letter-spacing: -0.28px;
}
.bottomSec div strong {
  color: var(--tekst-display-gray-01, #2f2a2a);
  font-family: "Libre Franklin";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: -0.28px;
}

.adminPostepMobile {
  padding: 10px 20px;
  background: var(--CTA-orange, #f0a139);
  height: 55px;
}

.reaLeftzakres {
  display: flex;
  align-items: center;
  color: #000;
  gap: 10px;
}

.reaLeftzakres .leftNumberW {
  font-family: "Libre Franklin";
  font-size: 14px;
  font-style: normal;
  height: 100%;
  font-weight: 500;
  line-height: 154%; /* 21.56px */
  letter-spacing: -0.56px;
}

.toggleItemPracMobile {
  display: flex;
  flex-direction: row;
  padding: 0 10px;
  gap: 20px;
  align-items: center;
}

.toggleItemPracMobile .iconQQ {
  width: 16px;
  height: 16px;
  display: flex;
  color: black;
  font-size: 24px;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  border: 0.5px solid #000;
}

.theadSmallMobile tr th {
  color: var(--CTA-orange, #f0a139);
  font-family: "Libre Franklin";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 154%; /* 21.56px */
  letter-spacing: -0.56px;
  text-transform: none;
}

.theadSmallMobile tr th p.yellow {
  text-transform: none;
}

.reaLeftzakres .divRightZakres {
  padding-left: 10px;
  border-left: 1px solid var(--tekst-body-gray02, #d1d1d1);
}

.adminPostepMobile a {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.adminPostepMobile .text {
  color: var(--tekst-body-gray03, #f7f7f7);
  font-family: "Libre Franklin";
  font-size: 19px;
  font-style: normal;
  font-weight: 600;
  line-height: 154%; /* 29.26px */
  letter-spacing: -0.76px;
}
.adminPostepMobile .borderRight {
  padding-right: 10px;
  border-right: 1px solid var(--tekst-body-gray03, #f7f7f7);
}
.adminPostepMobile .circleWhite {
  width: 20px;
  height: 20px;
  border: 1px solid white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.gridTodoList {
  display: flex;
  flex-direction: column;
}

#adminListingTodo .gridTodoList {
  max-height: 600px;
  overflow: auto;
}

.todolistItem .left {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}

.todolistItem .left svg {
  font-size: 26px;
}

.todolistItem {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 400px;
  cursor: pointer;
  padding: 10px;
  border-radius: 6px;
  text-overflow: ellipsis;
  overflow: hidden;
  justify-content: space-between;
}

.todolistItem:hover {
  background: #ececec;
}

.todoForm .inputTypical {
  width: 100%;
}
.innerForm .remove {
  position: absolute;
  bottom: -70px;
  left: 0;
  display: flex;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 100px;
  border: 1px solid var(--CTA-orange, #f03939);
  color: var(--CTA-orange, #f03939);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  background: transparent;
  cursor: pointer;
  letter-spacing: -0.28px;
}
.innerForm .grey {
  position: absolute;
  bottom: -130px;
  right: 0;
  display: flex;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 100px;
  border: 1px solid var(--CTA-orange, #2f2a2a);
  color: var(--CTA-orange, #2f2a2a);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  background: transparent;
  cursor: pointer;
  letter-spacing: -0.28px;
}
.innerForm .grey:hover {
  background: #2f2a2a;
  color: white;
}
.innerForm .remove:hover {
  background: #f03939;
  color: white;
}

.todolistItem .title {
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  max-width: calc(400px - 36px);
}

.inputPlacehoder {
  padding: 20px;
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}
#adminListingTodo .todoContent .content {
  color: black;
}
.flexHomeTop .profile img {
  width: 100px;
  height: 100px;
  max-width: 100px;
  object-fit: cover;
  border-radius: 50%;
}
.sidebarTodoWrapper .todolistItem .title {
  color: white;
}

.sidebarTodoWrapper .accepted svg {
  color: white;
}
.sidebarTodoWrapper .todolistItem:hover svg,
.sidebarTodoWrapper .todolistItem:hover .title,
.sidebarTodoWrapper .todolistItem:hover .content {
  color: #000;
}

.sidebarTodoWrapper form .inputTypical {
  width: 100%;
}

.sidebarTodoWrapper .todoList {
  max-height: 60vh;
  overflow-y: auto;
}
.sidebarTodoWrapper .todolistItem {
  height: fit-content;
}
.todoContent {
  display: flex;
  flex-direction: column;
}

.todoContent .content {
  color: white;
  font-size: 13px;
}
.todolistItem.sub {
  margin-left: 24px;
  max-width: 376px;
  width: calc(100% - 24px);
}
.todolistItem.sub svg {
  font-size: 20px;
}
.specWrap button,
.specWrap button:hover {
  width: 100%;
  padding: 12px;
  border-radius: 2px;
  border: 1px solid var(--tekst-display-gray-01, #2f2a2a);
  background: #fff;
  color: var(--tekst-display-gray-01, #2f2a2a);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.32px;
}

.removeModalBG {
  position: fixed;
  z-index: 999999;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: #00000065;
  cursor: pointer;
}

.removeModalContent {
  z-index: 9999999;
  background: white;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  padding: 20px;
  border-radius: 10px;
  right: 0;
  text-align: center;
  width: 350px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 250px;
  min-height: fit-content;
  max-width: 90vw;
  position: absolute;
  top: calc(
    50% - 175px
  ); /* position the top  edge of the element at the middle of the parent */
}
.removeModalContent .buttons {
  display: flex;
  gap: 20px;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 20px;
  justify-content: space-between;
}
.newMessage {
  padding-bottom: 50px;
}

.greyButtonPtaszek path,
.greyButtonPtaszek rect {
  stroke: #7f7f7f;
}

.BGOpacity {
  background: #000000b0;
  z-index: 99;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  position: fixed;
}
.modalImage {
  position: fixed;
  top: 7.5vh;
  left: 7.5vw;
  text-align: center;
  z-index: 100;
  width: 85vw;
  height: 85vh;
}
.modalImage img {
  max-height: 100%;
}
.modalImage .topNavigationDetail .icon {
  border-color: white;
}

.modalImage .topNavigationDetail {
  color: white;
}

#updateForm .col-xl-4 {
  display: flex;
  flex-direction: column;
}
.gridDetail .gridItemProject.postepPracDesktop .topItem img {
  height: unset;
  aspect-ratio: 1/1;
}
@media only screen and (max-width: 1199px) {
  .imageMagnifierWrapper {
    top: 10vh;
  }
  .imageShoppingListMobile {
    height: 100px;
    width: auto;
    object-fit: cover;
    max-width: 100%;
    border-radius: 10px;
  }
  #leftSideLogin {
    min-height: 100vh;
    background: url("/public/ossipLogin.jpeg") no-repeat;
    background-size: cover;
  }
  #leftSideLogin .innerLeft {
    background: white;
    padding: 20px;
    margin-top: 40px;
  }
  #rightSideLogin {
    display: none !important;
  }
  .noMobile {
    display: none !important;
  }
  .myDropDown li.contact {
    align-items: center;
    padding: 10px 0;
  }
  .myDropDown li.contact p {
    margin-bottom: 0;
  }
  .myDropDown {
    position: absolute;
    top: 100%;
    z-index: 9;
    width: 100%;
    margin-bottom: 0;
    padding-left: 10px;
    border-radius: 0px 0px 6px 6px;
    border-right: 1px solid var(--tekst-body-gray02, #d1d1d1);
    border-bottom: 1px solid var(--tekst-body-gray02, #d1d1d1);
    border-left: 1px solid var(--tekst-body-gray02, #d1d1d1);
    background: #fff;
    box-shadow: 0px 8px 17px 0px rgba(0, 0, 0, 0.1),
      0px 31px 31px 0px rgba(0, 0, 0, 0.09),
      0px 70px 42px 0px rgba(0, 0, 0, 0.05),
      0px 125px 50px 0px rgba(0, 0, 0, 0.01),
      0px 195px 55px 0px rgba(0, 0, 0, 0);
  }
  #topSidebarMobile {
    padding: 20px;
    position: relative;
    z-index: 100;
  }

  body .logoSmall {
    height: 30px;
  }

  #topSidebarMobile .d-flex.flex-row {
    gap: 10px;
  }
  #topSidebarMobile .d-flex.flex-row h1 {
    color: var(--tekst-display-gray-01, #2f2a2a);
    font-family: "Libre Franklin";
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 28.8px */
    margin-bottom: 0;
    letter-spacing: -0.96px;
  }
  .rowFullWidth.row {
    padding-right: 0;
  }
  #homeUser #circleProc .bottomText {
    display: none;
  }
  #homeUser #circleProc {
    flex-direction: column-reverse;
  }
  .rowFullWidth.row > .col-sm-12 {
    min-height: unset;
  }

  .flexHomeTop {
    padding: 0 2vw;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 20px;
    padding-top: 40px;
    align-items: center;
    padding-bottom: 20px;
    border-bottom: 1px solid var(--tekst-body-gray02, #d1d1d1);
  }
  .flexHomeTop .profile {
    min-width: 100px;
  }
  .checkBoxes .checking {
    gap: 10px;
  }
  .checkBoxes .checking button {
    width: 20px;
    min-width: 20px;
    height: 20px;
  }
  .checkBoxes .checking p {
    font-size: 14px;
  }
  .timeLineObject .content {
    height: 100px;
  }
  .flexHomeTop strong {
    color: var(--tekst-display-gray-01, #2f2a2a);
    font-size: 19px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.38px;
    margin-bottom: 0;
  }

  .AddPlik {
    height: 120px;
  }

  .flexHomeTop p {
    color: var(--tekst-display-gray-01, #2f2a2a);
    font-size: 19px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.38px;
    margin-bottom: 0;
  }
  .timeLINEOBJ {
    padding: 0 !important;
  }
  .timeLineObject {
    width: 200px;
  }
  .circleImageClassic img {
    height: 40px;
    width: 40px;
    min-width: 40px;
  }
  .tableKontakt td {
    font-size: 14px;
  }
  .nameTd p {
    margin-top: 0;
  }
  body tr {
    -ms-touch-action: manipulation;
    touch-action: manipulation;
  }
  .bottomButton.Listing button {
    margin-top: 2rem;
    border-radius: 100px;
    border: 1px solid var(--tekst-display-gray-01, #2f2a2a);
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
    color: var(--tekst-display-gray-01, #2f2a2a);
    text-align: center;
    font-family: "Libre Franklin";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 116%; /* 18.56px */
    background: white;
    letter-spacing: -0.32px;
    padding: 12px 24px 12px 24px;
  }
  .bottomButton.Listing button:hover {
    color: white;
    background: #2f2a2a;
  }
  .bottomButton.Listing button:hover svg {
    color: white;
  }
  .bottomButton.Listing button svg {
    color: #2f2a2a;
  }

  /* .swiperListing .swiper-slide {
    width: 90% !important;
    transition: all ease-in-out 500ms;
  }
  .swiperListing .swiper-slide {
    margin-left: auto;
    transition: all ease-in-out 500ms;
  }
  .swiperListing .swiper-slide-prev {
    margin-left: 10%;
    transition: all ease-in-out 500ms;
  } */

  .swiperListing.poprawki .topUklad {
    min-height: 330px;
  }
  .arrows {
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
  }
  .mainImage {
    border: 2px solid #777;
  }

  .bottomSinglePort {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .portfolioDetailTop {
    display: flex;
    flex-direction: row;
    padding: 0px 0;
    gap: 10px;
  }

  .arrows .arrow svg {
    font-size: 30px;
  }

  .portfolioDetailTop svg {
    font-size: 40px;
    color: #2f2a2a;
    padding-right: 10px;
    border-right: 1px solid #d1d1d1;
  }

  .portfolioDetailTop .rightSideFlex p {
    margin-bottom: 0;
    color: var(--tekst-display-gray-01, #2f2a2a);
    font-family: "Libre Franklin";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 22.4px */
    letter-spacing: -0.64px;
  }

  .gallery .swiper-button-prev {
    display: none;
  }
  .gallery .swiper-button-next {
    display: none;
  }
  .portfolioDetailTop .rightSideFlex p.lightDate {
    color: var(--tekst-body-gray01, #777);
    font-family: "Libre Franklin";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 16.8px */
  }
  .projectItem {
    flex-direction: column;
    gap: 20px;
    align-items: baseline;
  }
  .projectItem .totheLeft p {
    color: #000;
    font-family: "Libre Franklin";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 154%; /* 21.56px */
    letter-spacing: -0.28px;
  }

  .FormMobile {
    position: fixed;
    left: 0;
    z-index: 99;
    top: 0;
    width: 100vw;
    height: 100%;
    padding: 90px 20px;
    background: white;
  }
  .FormMobile .dropdown {
    width: 100%;
  }

  .smoller {
    color: #000;
    text-align: center;
    font-family: "Libre Franklin";
    font-size: 12px;
    font-style: normal;
    padding: 0 10px;
    font-weight: 400;
    line-height: 120%; /* 14.4px */
    letter-spacing: -0.24px;
  }
  .FormMobile .dropdown button {
    border: 1px solid var(--CTA-orange, #f0a139);
    border-radius: 0;
  }

  .FormMobile .inputTypical {
    width: 100%;
    border: 1px solid var(--CTA-orange, #f0a139);
    border-radius: 0;
  }
  .filter {
    background: var(--background-gray00, #f8f8f8);
    padding: 20px 10px;
  }
  .filter .dropdown,
  .filter .dropdown button {
    width: 100%;
  }
  .filter .dropdown button {
    display: flex;
    justify-content: space-between;
    border: 2px solid var(--background-gray01, #ececec);
    background: #fff;
    padding: 10px;
    color: #000;
    font-family: "Libre Franklin";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 154%; /* 21.56px */
    letter-spacing: -0.56px;
  }
  .nameTd .nameShoppingList {
    color: var(--tekst-display-gray-01, #2f2a2a);
    font-family: "Libre Franklin";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 154%; /* 24.64px */
    letter-spacing: -0.64px;
  }
  .postepyPracMobile {
    display: flex;
    flex-direction: row;
    gap: 10px;
    padding: 10px 20px;
    flex-wrap: wrap;
  }
  .postepPracItem {
    width: calc(50% - 10px);
  }
  .swiperPortfolioDetail .swiper-slide img {
    height: 100px;
    object-fit: cover;
    width: 100%;
  }
  .postepPracItem > strong {
    height: 48px;
    display: block;
    overflow: hidden;
  }
  .postepPracItem .wrapImage {
    position: relative;
    height: 100px;
  }
  .postepPracItem .wrapImage img {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }
  .mobile100 {
    width: 100%;
  }
  .postepPracItem button {
    position: absolute;
    left: 10%;
    right: 10%;
    top: 40%;
    color: var(--background-gray00, #f8f8f8);
    text-align: center;
    font-family: "Libre Franklin";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 116%; /* 16.24px */
    letter-spacing: -0.56px;
    border-radius: 100px;
    border: 1px solid var(--tekst-body-gray03, #f7f7f7);
    background: transparent;
  }
  .postepPracItem .icons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
    padding: 10px 0;
    border-bottom: 1px solid var(--tekst-body-gray02, #d1d1d1);
  }
  .postepPracItem .icon {
    width: 42px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0.5px solid #000;
  }
  .note .noteTop {
    flex-wrap: wrap;
  }
  .addZaliczkaMobile {
    height: 45px;
  }

  .topUklad.notatka {
    background: #fff;
    height: 100%;
    display: flex;
    flex-direction: column;
    height: 300px;
    align-items: flex-start;
    gap: 2px;
  }
  .topUklad.notatka .flexTop {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: space-between;
  }
  .topUklad.notatka .flexTop input {
    padding: 20px;
  }
  .topUklad.notatka .flexTop strong {
    color: var(--tekst-display-gray-01, #2f2a2a);
    font-family: "Libre Franklin";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 16.8px */
    letter-spacing: -0.28px;
  }
  .topUklad.notatka .flexTop p {
    color: var(--tekst-body-gray01, #777);
    margin-bottom: 0;
    font-family: "Libre Franklin";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 14.4px */
    letter-spacing: -0.24px;
  }
  .topUklad.notatka .noteContent {
    padding: 20px;
  }
  .mobileSwipeBody {
    display: table-caption;
  }
  .mobileSwipeBody tr {
    width: 100%;
    display: table;
  }
  .classicTable tbody.mobileSwipeBody tr td:first-child {
    padding-left: 0;
    width: 50px;
  }
  .classicTable tbody.mobileSwipeBody tr td:nth-child(2) {
    width: 100px;
  }
  .mobileSwipeBody .rstdi .delete {
    justify-content: flex-end;
    right: 0;
  }
  .nameTd p {
    font-size: 14px;
  }
  .rstdi .content p {
    max-height: 52px;
    overflow: hidden;
  }
  .messageWrapper .top,
  .messageWrapper .top div {
    display: flex;
    gap: 10px;
    flex-direction: column;
    align-items: start;
  }
  .messageWrapper .title .messageFrom {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-bottom: 20px;
  }
  .messageWrapper .title {
    border-top: 1px solid var(--tekst-body-gray02, #d1d1d1);
    padding-top: 20px;
  }
  .dropDownMobileKomunikator {
    position: relative;
  }
  .dropDownMobileKomunikator button {
    border-radius: 6px;
    border: 1px solid var(--tekst-body-gray02, #d1d1d1);
    background: #fff;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 10px;
  }

  .lineSmooth {
    width: 100%;
    height: 10px;
    min-width: 200px;
    text-align: center;
    position: relative;
  }
  .timeLineObject .image {
    height: 15px;
  }
  .lineSmooth .bottom {
    left: 45%;
  }
  .lineSmooth .top {
    left: 45%;
  }
  .topNavigationDetail {
    gap: 5px;
  }
  .topNavigationDetail.pe-2 {
    padding-left: 5px;
  }
}
